import React, { useState } from "react";
import { db } from './firebase-config'; // Import Firebase Firestore config
import { collection, addDoc } from "firebase/firestore"; // Import Firestore methods

export default function Book() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    checkin: "",
    checkout: "",
    roomType: "",
    roomNumber: "",
    paymentMethod: "",
    accountNumber: "",
    mobileProvider: "",
    price: "200", // You can change this to dynamic pricing logic if needed
    status: "occupied", // Set default status to 'occupied'
  });

  // Sample data for available rooms - Replace this with your data fetching logic
  const availableRooms = [
    { id: 1, number: "101" },
    { id: 2, number: "102" },
    { id: 3, number: "201" },
    { id: 4, number: "202" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add form data to the "bookings" collection in Firestore
      const docRef = await addDoc(collection(db, "bookings"), {
        name: formData.name,
        phone: Number(formData.phone), // Phone should be stored as a number
        checkIn: formData.checkin,
        checkOut: formData.checkout,
        roomType: formData.roomType,
        roomNumber: formData.roomNumber,
        paymentMethod: formData.paymentMethod,
        accountNumber: formData.accountNumber,
        mobileProvider: formData.mobileProvider,
        price: formData.price, // Assuming this is fixed or can be dynamically calculated
        status: formData.status,
      });
      console.log("Booking added with ID: ", docRef.id);

      // Optionally, clear the form after submission
      setFormData({
        name: "",
        phone: "",
        checkin: "",
        checkout: "",
        roomType: "",
        roomNumber: "",
        paymentMethod: "",
        accountNumber: "",
        mobileProvider: "",
        price: "200",
        status: "occupied",
      });

      alert("Room booked successfully!");

    } catch (e) {
      console.error("Error adding booking: ", e);
    }
  };

  return (
    <>
      <div className="container-fluid booking pb-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container">
          <div className="bg-white shadow" style={{ padding: "35px" }}>
            <form onSubmit={handleSubmit}>
              {/* Form fields for name, phone, checkin, checkout, etc. */}
              <div className="row g-2">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="tel"
                    name="phone"
                    className="form-control"
                    placeholder="Enter Your Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="row g-2 mt-3">
                <div className="col-md-3">
                  <input
                    type="date"
                    name="checkin"
                    className="form-control"
                    placeholder="Check in"
                    value={formData.checkin}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-3">
                  <input
                    type="date"
                    name="checkout"
                    className="form-control"
                    placeholder="Check out"
                    value={formData.checkout}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-3">
                  <select
                    name="roomType"
                    className="form-select"
                    value={formData.roomType}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Room Type</option>
                    <option value="junior_suite">Mini suite</option>
                    <option value="executive_suite">Executive Suite</option>
                    <option value="super_deluxe"> Deluxe</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <select
                    name="roomNumber"
                    className="form-select"
                    value={formData.roomNumber}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Room Number</option>
                    {availableRooms.map((room) => (
                      <option key={room.id} value={room.number}>
                        {room.number}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row g-2 mt-3">
                <div className="col-md-3">
                  <select
                    name="paymentMethod"
                    className="form-select"
                    value={formData.paymentMethod}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Payment Method</option>
                    <option value="credit_card">Credit Card</option>
                    <option value="mobile_money">Mobile Money</option>
                    <option value="cash">Cash</option>
                  </select>
                </div>
              </div>

              {formData.paymentMethod === "credit_card" && (
                <div className="row g-2 mt-3">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="accountNumber"
                      className="form-control"
                      placeholder="Enter Account Number"
                      value={formData.accountNumber}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              )}

              {formData.paymentMethod === "mobile_money" && (
                <div className="row g-2 mt-3">
                  <div className="col-md-6">
                    <select
                      name="mobileProvider"
                      className="form-select"
                      value={formData.mobileProvider}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Mobile Provider</option>
                      <option value="airtel">Airtel</option>
                      <option value="mtn">MTN</option>
                      <option value="zamtel">Zamtel</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="tel"
                      name="accountNumber"
                      className="form-control"
                      placeholder="Enter Mobile Number"
                      value={formData.accountNumber}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              )}

              <div className="row g-2 mt-3">
                <div className="col-md-12 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn"
                    style={{ backgroundColor: "#E25217", color: "#fff", padding: "10px 20px", borderRadius: "5px" }}
                  >
                   Book Now
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
