import React from "react";
import Heading from "../common/Heading";
import { about } from "../data/Data";

export default function About() {
  return (
    <>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h6 className="section-title text-start text-uppercase" style={{ color: "#E25217" }}>
                About Us
              </h6>
              <h1 className="mb-4">
                Welcome to{" "}
                <span className="text-uppercase" style={{ color: "#E25217" }}>
                  Mungwi Park Gardens
                </span>
              </h1>

              <p className="mb-4">
              Mungwi Park Gardens event and lodges has been in existence for over two years now. It is a serene and picturesque lodge offering  
High quality hospitality services that suit every need. The lodge is located off Mungwi road in Lusaka west. 
We pride ourselves with an open and approachable leadership style that realizes that partnership is essential and fundamental basis  
For the success of any establishment. To maintain a leadership position in the hospitality industry, service excellence is critical to the  
Successful management of our lodge.
              </p>
                  <div className="row g-3 pb-4" style={{ color: "#E25217", padding: "5px" }}>
                  {about.map((item, key) => (
                  <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s" key={key}>
                  <div className="border rounded p-1">
                  <div className="border rounded text-center p-4">
                  <div className="icon d-inline-block">
                  {item.icon}
                  </div>
                  <h2 className="mb-1" data-toggle="counter-up">
                  {item.count}
                  </h2>
                  <p className="mb-0">{item.text}</p>
                  </div>
                  </div>
                  </div>
                  ))}
                  </div>
              <a className="btn btn-primary py-3 px-5 mt-2" href="">
                Explore More
              </a>
            </div>
            <div className="col-lg-6">
              <div className="row g-3">
                <div className="col-6 text-end">
                  <img
                    className="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.1s"
                    src="/assets/img/about-1.jpg"
                    style={{ marginTop: "25%" }}
                    alt="About 1"
                  />
                </div>
                <div className="col-6 text-start">
                  <img
                    className="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.3s"
                    src="/assets/img/about-2.jpg"
                    alt="About 2"
                  />
                </div>
                <div className="col-6 text-end">
                  <img
                    className="img-fluid rounded w-50 wow zoomIn"
                    data-wow-delay="0.5s"
                    src="/assets/img/about-3.jpg"
                    alt="About 3"
                  />
                </div>
                <div className="col-6 text-start">
                  <img
                    className="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.7s"
                    src="/assets/img/about-4.jpg"
                    alt="About 4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
