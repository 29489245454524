import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth"; 
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD8__KwRIayesuqa7Ua13SeBIO_bf06o94",
  authDomain: "mungwi-park.firebaseapp.com",
  projectId: "mungwi-park",
  storageBucket: "mungwi-park.appspot.com",
  messagingSenderId: "408257470794",
  appId: "1:408257470794:web:4a49c12f1c9f48e991a1b3",
  measurementId: "G-PTLLRNDRD3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Firestore database
const auth = getAuth(app); // Firebase authentication

// Google Auth Provider
const googleProvider = new GoogleAuthProvider();

// Export the auth, provider, and any other Firebase services
export { app, db, auth, googleProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword };
