export const navList = [
  {
    id: 1,
    path: "/",
    text: "Home",
  },
  {
    id: 2,
    path: "/about",
    text: "About",
  },
  {
    id: 3,
    path: "/services",
    text: "Services",
  },
  {
    id: 4,
    path: "/rooms",
    text: "Rooms",
  },
  {
    id: 5,
    path: "/page",
    text: "Page",
    subItems: [
      {
        id: 51,
        path: "/booking",
        text: "Booking",
      },
      {
        id: 52,
        path: "/team",
        text: "Our Team",
      },
      {
        id: 53,
        path: "/testimonial",
        text: "Testimonial",
      },
    ],
  },
  {
    id: 6,
    path: "/contact",
    text: "Contact",
  },
];
export const socialIcons = [
  {
    icon: <i className="fab fa-facebook-f"></i>,
  },
  {
    icon: <i className="fab fa-twitter"></i>,
  },
  {
    icon: <i className="fab fa-instagram"></i>,
  },
  {
    icon: <i className="fab fa-linkedin-in"></i>,
  },
  {
    icon: <i className="fab fa-youtube"></i>,
  },
];

export const carouselData = [
  {
    img: "../assets/img/carousel-1.jpg",
    title: "Welcome to Mungwi Park Gardens",
    subtitle: "Unforgettable Experiences",
    btn1: "Our Room",
    btn2: "Book Room",
  },
  {
    img: "../assets/img/carousel-2.jpg",
    title: "Where you can truly feel at home.",
    subtitle: "Natural Surrounding",
    btn1: "Our Room",
    btn2: "Book Room",
  },
];
export const about = [
  {
    icon: <i class="fa fa-hotel fa-2x text-primary mb-2"></i>,
    text: "Rooms",
    count: "35",
  },
  {
    icon: <i class="fa fa-users fa-2x text-primary mb-2"></i>,
    text: "Staffs",
    count: "20",
  },
  {
    icon: <i class="fa fa-users-cog fa-2x text-primary mb-2"></i>,
    text: "Clients",
    count: "10,321",
  },
];

export const services = [
  {
    icon: <i class="fa fa-hotel fa-2x text-primary"></i>,
    name: "Rooms & Appartments",
    discription: "Relax in our standard deluxe accommodations, inclusive of a hearty continental breakfast. Whether you are here for business or leisure, our rooms offer the perfect blend of comfort and style, all at affordable rates.",
},
{
    icon: <i class="fa fa-utensils fa-2x text-primary"></i>,
    name: "Bar & Restaurant",
    discription: "Whether you're in the mood for a traditional meal or a taste of something new, our chefs prepare each dish with care and passion. Savor every bite, knowing you're dining in one of Lusaka's finest spots.",
},
{
    icon: <i class="fa fa-music fa-2x text-primary"></i>,
    name: "Music & Enjoyments",
    discription: "The perfect evening awaits at our fully stocked bar. Sip on refreshing drinks, enjoy live music, and soak in the lively atmosphere that makes every night at Mungwi Park Gardens an experience to remember.",
},
{
    icon: <i class="fa fa-swimmer fa-2x text-primary"></i>,
    name: "Swimming Pool",
    discription: "Best pool experience for both children and adults.Dive into serenity at Mungwi Park Gardens swimming pool, Where every splash is a moment of bliss",
},
{
    icon: <i class="fa fa-glass-cheers fa-2x text-primary"></i>,
    name: "Conferencing, Event & Party",
    discription: "Your special moments deserve a special venue. Mungwi Park Gardens is the perfect setting for weddings, engagement parties, and gatherings. Let us help you celebrate life's milestones with elegance and charm.",
},
{
    icon: <i class="fa fa-child fa-2x text-primary"></i>,
    name: "Kids Play Park",
    discription: "Families are always welcome here. Our spacious children's playground offers a safe and enjoyable space for the little ones to laugh, play, and make memories.",
},
{
    icon: <i class="fa fa-calendar-check fa-2x text-primary"></i>,
    name: "Bookings & Hiring",
    discription: "Make your reservations for rooms, events, and equipment seamlessly through our user-friendly booking system. We cater to all your needs with flexibility and ease.",
},
{
    icon: <i class="fa fa-concierge-bell fa-2x text-primary"></i>,
    name: "Our Catering Team",
    discription: "Offers quality, elegant and high standard service to our clients. That exceed expectations and leaving a lasting impression.",
},
{
    icon: <i class="fa fa-paint-brush fa-2x text-primary"></i>,
    name: "Outside Décor",
    discription: "Let us transform your outdoor events with stunning décor solutions that capture the essence of your special day, from intimate gatherings to grand celebrations.",
},

{
  icon: <i class="fa fa-chalkboard-teacher fa-2x text-primary"></i>,
  name: "Skills Institute",
  discription: "Our Skills Institute offers a variety of courses and workshops to help you grow both personally and professionally. From hospitality training to creative arts, there's something for everyone.",
},
// {
//   icon: <i class="fa fa-paint-brush fa-2x text-primary"></i>,
//   name: "Outside Décor",
//   discription: "Let us transform your outdoor events with stunning décor solutions that capture the essence of your special day, from intimate gatherings to grand celebrations.",
// },

// {
// icon: <i class="fa fa-chalkboard-teacher fa-2x text-primary"></i>,
// name: "Skills Institute",
// discription: "Our Skills Institute offers a variety of courses and workshops to help you grow both personally and professionally. From hospitality training to creative arts, there's something for everyone.",
// },


];
export const team = [
  {
    image: "../assets/img/team-3.jpg",
    name: "Victor Nyirenda",
    designation: "Director",
  },
  {
    image: "../assets/img/team-3.jpg",
    name: "Daison lungu",
    designation: "Events Manager",
  },
  {
    image: "../assets/img/team-3.jpg",
    name: "Mabvuto Nyirenda",
    designation: "Operation Manager",
  },
  // {
  //   image: "../assets/img/team-3.jpg",
  //   name: "Gerald Limbando",
  //   designation: "Designation",
  // },
];

export const footerItem = [
  {
    id: 1,
    header: "Company",
    UnitItem: [
      {
        name: "About Us",
      },
      {
        name: "Contact Us",
      },
      {
        name: "Privacy Policy",
      },
      {
        name: "Terms & Condition",
      },
      {
        name: "Support",
      },
    ],
  },
  {
    id: 2,
    header: "Services",
    UnitItem: [
      {
        name: "Food & Restaurant",
      },
      {
        name: "Lodging Rooms",
      },
      {
        name: "Children Gaming park",
      },
      {
        name: "Event & Party",
      },
      {
        name: "swimming pool",
      },
    ],
  },
];

export const footerContact = [
  {
    icon: <i className="fa fa-map-marker-alt me-3"></i>,
    name: "Plot Number 90401 Chimutengo Road off Mungwi Road, Lusaka West, Chilanga.Po Box 38385",
  },
  {
    icon: <i className="fa fa-phone-alt me-3"></i>,
    name: "+260771407186 +2607619146971",
  },
  {
    icon: <i className="fa fa-envelope me-3"></i>,
    name: "Imfo@mungwiparkgardens.com",
  },
];

export const contact = [
  {
    icon: <i class="fa fa-phone text-primary me-2"></i>,
    title: "Booking",
    email: "+260771407186/+2607619146971+260957534616",
  },
  {
    icon: <i class="fa fa-envelope-open text-primary me-2"></i>,
    title: "Technical",
    email: "Imfo@mungwiparkgardens.com",
  },
  {
    icon: <i class="fa fa-envelope-open text-primary me-2"></i>,
    title: "General",
    email: "Po Box 38385",
  },
];
export const testimonial = [
  {
    description:
      "They have good services and their food test nice🙂",
    name: "Azariah W-Õràçlê",
    profession: "Profession",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-1.jpg",
  },
  {
    description:
      "I love this place it's really cool fresh air and free mind it's funny been there",
    name: "Felisha Kayz Angel",
    profession: "Profession",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-2.jpg",
  },
  {
    description:
      "Cold beer, good food and lot's of fresh air",
    name: "Matthew ",
    profession: "Profession",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
];

export const roomItems = [
  {
    img: "../assets/img/room-1.jpg",
    price: "K400/night",
    name: "Deluxe",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "DSTV, Wi-Fi, swimming and work space.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },

  {
    img: "../assets/img/room-2.jpg",
    price: "K550/night",
    name: "Executive Suite",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Continental, DSTV, Wi-Fi, swimming and work space.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },
  {
    img: "../assets/img/room-3.jpg",
    price: "k350/night",
    name: "Mini Suite",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "DSTV, Wi-Fi, swimming.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },
];

export const facility = [
  {
    icon: <i class="fa fa-bed text-primary me-2"></i>,
    quantity: 1,
    facility: "bed",
  },
  {
    icon: <i class="fa fa-bath text-primary me-2"></i>,
    quantity: 1,
    facility: "bath",
  },
  {
    icon: <i class="fa fa-wifi text-primary me-2"></i>,
    facility: "Wifi",
  },
];
